import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SampleFilters } from "../../types/QualityControlModels";
import { PaperItem } from "../../utilities/PaperItem";
import { useRelativeTime } from "../../hooks/general/RelativeTime";
import { useGetLegacySamplesByFilter } from "../../queries/QualityControlQueries";
import { Badge, Button, Card, CardContent, CardHeader, Chip, Divider, Grid, Link, List, ListItem, ListItemButton, MenuItem, Popover, Select, Stack, Typography } from "@mui/material";
import { DateRangeIcon, DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { isEqual } from "lodash";
import { FilterAlt, LocationOn } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export const LegacySamples = () => {
    const { lastYear, today } = useRelativeTime();
    const [sampleFilters, setSampleFilters] = useState<SampleFilters>({
        startDate: lastYear,
        endDate: today,
        statuses: null,
        testTypes: null,
        compositeTypes: null,
        labTechs: null,
        samplers: null,
        operators: null,
        sites: [1],
        plants: null,
        locations: null,
        specificLocations: null,
        viewModeToggle: "simple",
    });
    const { refetch, isFetching } = useGetLegacySamplesByFilter(sampleFilters);

    const ChipFilterPopover = ({ id, label, icon = null, filter = null, filterActive, children }) => {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const handleClick = useCallback(
            (e: React.MouseEvent<any>) => {
                setAnchorEl(e.currentTarget);
            },
            [setAnchorEl]
        );

        const handleClose = useCallback((e) => {
            setAnchorEl(null);
        }, []);

        const handleRemoveFilter = useCallback((e) => {
            const filterName = id.substring(0, id.indexOf("Filter"));
            setSampleFilters((prevState) => ({ ...prevState, [filterName]: null }));
        }, []);

        return filterActive ? (
            <>
                <Badge badgeContent={filter?.length ?? undefined} color="secondary">
                    <Chip id={id} label={label} icon={icon} clickable onClick={handleClick} onDelete={filter?.length === 0 ? handleRemoveFilter : undefined} />
                </Badge>

                <Popover
                    id={id}
                    open={anchorEl?.id === id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card sx={{ width: "25vw" }}>
                                <CardHeader
                                    sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
                                    title={
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <FilterAlt fontSize="small" />
                                            <Typography variant="subtitle2">{label}</Typography>
                                        </Stack>
                                    }
                                />
                                <CardContent>{children}</CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Popover>
            </>
        ) : (
            <></>
        );
    };

    const DateFilter = () => {
        const [dateRange, setDateRange] = useState({
            startDate: sampleFilters.startDate,
            endDate: sampleFilters.endDate,
        });

        const isDateFilterChanged = useMemo(() => !isEqual({ ...dateRange }, { startDate: sampleFilters.startDate, endDate: sampleFilters.endDate }), [dateRange]);
        const handleStartDateChange = useCallback((startDate: Dayjs) => setDateRange((prevState) => ({ ...prevState, startDate: startDate.toDate() })), []);

        const handleEndDateChange = useCallback((endDate: Dayjs) => setDateRange((prevState) => ({ ...prevState, endDate: endDate.toDate() })), []);

        const handleApply = useCallback(() => {
            setSampleFilters((prevState) => ({ ...prevState, ...dateRange }));
        }, [dateRange]);

        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <DateTimePicker label="Start Date" onChange={handleStartDateChange} value={dayjs(dateRange.startDate)} />
                    <Stack direction="row" spacing={1}>
                        <small>
                            <Link sx={{ cursor: "pointer" }} onClick={() => setDateRange((prevState) => ({ ...prevState, startDate: lastYear }))}>
                                Last Year
                            </Link>
                        </small>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker label="End Date" onChange={handleEndDateChange} value={dayjs(dateRange.endDate)} />
                    <Stack direction="row" spacing={1} marginLeft={0}>
                        <small>
                            <Link sx={{ cursor: "pointer" }} onClick={() => setDateRange((prevState) => ({ ...prevState, endDate: today }))}>
                                Today
                            </Link>
                        </small>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row-reverse">
                        <Button disableElevation variant="contained" color="primary" disabled={!isDateFilterChanged} onClick={handleApply}>
                            Apply
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    };

    const LocationFilter = () => {
        return (
            <List>
                <ListItemButton onClick={() => setSampleFilters((prev) => ({ ...prev, sites: [1] }))} disabled={sampleFilters.sites?.includes(1)}>
                    Granbury
                </ListItemButton>
                <ListItemButton onClick={() => setSampleFilters((prev) => ({ ...prev, sites: [2] }))} disabled={sampleFilters.sites?.includes(2)}>
                    Tolar
                </ListItemButton>
                <ListItemButton onClick={() => setSampleFilters((prev) => ({ ...prev, sites: [3] }))} disabled={sampleFilters.sites?.includes(3)}>
                    West Texas
                </ListItemButton>
                <ListItemButton onClick={() => setSampleFilters((prev) => ({ ...prev, sites: [4] }))} disabled={sampleFilters.sites?.includes(4)}>
                    DSCO
                </ListItemButton>
            </List>
        );
    };

    const siteFilterLabel = useMemo(() => {
        if (sampleFilters.sites?.length) {
            switch (sampleFilters.sites[0]) {
                case 1:
                    return "Granbury";
                case 2:
                    return "Tolar";
                case 3:
                    return "West Texas";
                case 4:
                    return "DSCO";
                default:
                    return "Granbury";
            }
        }
    }, [sampleFilters.sites]);

    return (
        <PaperItem>
            <Typography variant="h5" gutterBottom>
                Legacy Database Export
            </Typography>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            <Stack direction="row" spacing={1}>
                <ChipFilterPopover
                    id="dateFilter"
                    filter={{
                        startDate: sampleFilters.startDate,
                        endDate: sampleFilters.endDate,
                    }}
                    filterActive={sampleFilters.startDate || sampleFilters.endDate}
                    icon={<DateRangeIcon />}
                    label={
                        <Typography variant="subtitle2">
                            {dayjs(sampleFilters.startDate)?.format("MM/DD/YYYY")}
                            {" - "}
                            {dayjs(sampleFilters.endDate)?.format("MM/DD/YYYY")}
                        </Typography>
                    }
                >
                    <DateFilter />
                </ChipFilterPopover>
                <ChipFilterPopover
                    id="sitesFilter"
                    filter={{ sites: sampleFilters.sites }}
                    filterActive={!!sampleFilters.sites}
                    icon={<LocationOn />}
                    label={<Typography variant="subtitle2">{siteFilterLabel}</Typography>}
                >
                    <LocationFilter />
                </ChipFilterPopover>
            </Stack>
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            <LoadingButton loading={isFetching} sx={{ marginTop: 2 }} variant="contained" onClick={() => refetch()} disabled={isFetching}>
                Export
            </LoadingButton>
        </PaperItem>
    );
};
