import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiBaseUrl as baseURL } from "../config/ApiConfig";
import axios from "axios";
import {
    SampleLocation,
    Sieve,
    SampleGroup,
    Sample,
    SampleFilters,
    SamplesByFilterResponse,
    SieveSet,
    CompositeType,
    TestType,
    LocationOverview,
    SieveGroup,
    RangeSetting,
    RangeSettingGroup,
    SampleSummation,
    RangeSummation,
    CharacteristicSummation,
    WashLogEntry,
} from "../types/QualityControlModels";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { User } from "../types/User";

const axiosClient = axios.create({ baseURL });

export const useGetSampleLocations = (options = {}) => {
    return useQuery(["locations"], async () => await axiosClient.get(`/api/location`).then((response) => (response?.data as SampleLocation[]) ?? []), { staleTime: Infinity, ...options });
};

export const useUpdateSampleLocationMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(async (updatedSampleLocation: SampleLocation) => await axiosClient.put(`/api/location/${updatedSampleLocation.id}`, updatedSampleLocation), {
        onSuccess: () => {
            queryClient.invalidateQueries(["locations"]);
        },
    });
};

export const useAddSampleLocationMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ siteId, plant, specificLocations, ...updatedSampleLocation }: { siteId: number } & SampleLocation) => {
            if (updatedSampleLocation.isNew) {
                const { id, ...sampleLocation } = updatedSampleLocation;
                return axiosClient.post(`/api/location`, sampleLocation);
            }
            return axiosClient.put(`/api/location/${updatedSampleLocation.id}`, updatedSampleLocation);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["locations"]);
            },
        }
    );
};

export const useGetSieveSets = () => {
    return useQuery(["sieveSets"], async () => await axiosClient.get(`/api/sieveSet`).then((response) => response?.data ?? []), { staleTime: Infinity });
};

export const useGetSieveSet = ({ sieveSetId, options = {} }) => {
    return useQuery(["sieveSet", sieveSetId], async () => await axiosClient.get(`/api/sieveSet/${sieveSetId}`).then((response) => response?.data as SieveSet), {
        enabled: !!sieveSetId,
        staleTime: Infinity,
        ...options,
    });
};

export const useAddOrUpdateSieve = () => {
    const queryClient = useQueryClient();
    //const { sieveSetId } = useParams();
    return useMutation(
        async (updatedSieve: Sieve) => {
            if (!updatedSieve.initialWeight) {
                updatedSieve.initialWeight = 0;
            }
            if (!updatedSieve.totalUses) {
                updatedSieve.totalUses = 0;
            }
            if (!updatedSieve.displayOrder) {
                updatedSieve.displayOrder = 0;
            }
            if (updatedSieve.isNew) {
                const { id, ...sieve } = updatedSieve;
                return await axiosClient.post(`/api/sieve`, sieve);
            } else {
                return await axiosClient.put(`/api/sieve/${updatedSieve.id}`, updatedSieve);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sieveSets"]);
                toast.success("Sieve saved successfully");
            },
        }
    );
};

export const useDeleteSieveSet = () => {
    const queryClient = useQueryClient();
    return useMutation(async (sieveSetId: number) => await axiosClient.delete(`/api/sieveSet/${sieveSetId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["sieveSets"]);
            //queryClient.refetchQueries(["sieveSets"]);
            toast.success("Sieve set deleted successfully");
        },
        onSettled: () => {
            queryClient.refetchQueries(["sieveSets"]);
        },
    });
};

export const useDeleteSieve = (sieveSetId) => {
    const queryClient = useQueryClient();
    return useMutation(async (sieveId: number) => await axiosClient.delete(`/api/sieve/${sieveId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["sieveSet", sieveSetId]);
            toast.success("Sieve deleted successfully");
        },
    });
};

export const useAddSieveSet = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (updatedSieveSet: any) => {
            if (updatedSieveSet.isNew) {
                // Quick fix for no identity insert
                // Remove the id that datagrid assigned
                const { id, ...sieveSet } = updatedSieveSet;
                updatedSieveSet = sieveSet;
            }
            return await axiosClient.post(`/api/sieveSet`, { ...updatedSieveSet }).then((response) => response?.data ?? []);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sieveSets"]);
            },
        }
    );
};

export const useAddSampleGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ sampleGroup }: { sampleGroup: SampleGroup }) => {
            return axiosClient.post(`/api/sampleGroup`, sampleGroup).then((response) => response?.data ?? []);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["filteredSamples"]);
            },
            onError: (error: Error) => {
                toast.error(error?.message ?? "An error occurred");
            },
        }
    );
};

export const useGetSampleGroupById = ({ sampleGroupId }) =>
    useQuery(["sampleGroup", sampleGroupId], async () => await axiosClient.get(`/api/sampleGroup/${sampleGroupId}`).then((response) => response?.data ?? []));

export const useGetSieveSetAnalysisById = (sieveSetAnalysisId) =>
    useQuery(
        ["sieveSetAnalysis", sieveSetAnalysisId],
        async () => {
            return await axiosClient.get(`/api/sieveAnalysis/${sieveSetAnalysisId}`).then((response) => response?.data ?? []);
        },
        { enabled: !!sieveSetAnalysisId }
    );

export const useGetSamples = ({ searchQuery, page, pageSize, options = {} }: { searchQuery: string; page: number; pageSize: number; options?: any }) => {
    return useQuery(
        ["samples", searchQuery, page, pageSize],
        async () => {
            const params = searchQuery !== "" ? { searchQuery, page, pageSize } : { page, pageSize };

            const response = await axiosClient.get(`/api/sample`, {
                params,
            });
            return response.data as Sample[];
        },
        { ...options }
    );
};

export const useGetSampleById = ({ sampleId, options = {} }) => {
    return useQuery(
        ["sample", sampleId],
        async () =>
            await axiosClient.get(`/api/sample/${sampleId}`).then((response) => {
                return response?.data ?? [];
            }),
        { enabled: !!sampleId, ...options }
    );
};

export const useGetSieveSetById = ({ sieveSetId, options = {} }) => {
    return useQuery(
        ["sieveSet", sieveSetId],
        async () =>
            await axiosClient.get(`/api/sieveSet/${sieveSetId}`).then((response) => {
                return response?.data ?? [];
            }),
        { enabled: !!sieveSetId, ...options, staleTime: Infinity }
    );
};

export const useGetCompositeTypes = () => {
    return useQuery(["compositeTypes"], async () => await axiosClient.get<CompositeType[]>(`/api/compositeType`).then((response) => response?.data), { staleTime: Infinity });
};

export const useGetTestTypes = () => {
    return useQuery(["testTypes"], async () => await axiosClient.get<TestType[]>(`/api/testType`).then((response) => response?.data), { staleTime: Infinity });
};

export const useGetSieveAnalysis = ({ sieveAnalysisId, options = {} }) => {
    return useQuery(["sieveAnalysis", sieveAnalysisId], async () => await axiosClient.get(`/api/sieveAnalysis/${sieveAnalysisId}`).then((response) => response?.data ?? []), {
        enabled: !!sieveAnalysisId,
        ...options,
        staleTime: Infinity,
    });
};

export const useGetCrushAnalysis = ({ crushAnalysisId, options = {} }) => {
    return useQuery(["crushAnalysis", crushAnalysisId], async () => await axiosClient.get(`/api/crushAnalysis/${crushAnalysisId}`).then((response) => response?.data ?? []), { ...options, staleTime: Infinity });
};

export const useGetSpecificSampleLocations = () => {
    return useQuery(["specificLocations"], async () => await axiosClient.get(`/api/specificLocation`).then((response) => response?.data ?? []), { staleTime: Infinity });
};

export const useGetWashLogEntries = () => {
    return useQuery(["washLogEntries"], async () => await axiosClient.get(`/api/sieveSet/washLog`).then((response) => response?.data ?? []), { staleTime: Infinity });
};

export const useCleanSieveSetMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((washLogEntry: WashLogEntry) => axiosClient.post(`/api/SieveSet/washLog`, washLogEntry), {
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};

export const useUpdateSampleMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (sample: Sample) => {
            sample.sieveSetAnalysis?.sieveAnalyses?.forEach((sieveAnalysis) => {
                if (sieveAnalysis.percentFinal === -Infinity) {
                    sieveAnalysis.percentFinal = 0;
                }
            });
            return axiosClient.put(`/api/Sample/${sample.id}`, sample);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sample"]);
            },
        }
    );
};

export const useGetRangeSettings = (options = {}) => {
    return useQuery(["rangeSettings"], async () => await axiosClient.get(`/api/rangeSetting`).then((response) => response?.data ?? []), { staleTime: Infinity, ...options });
};

export const useGetRangeSettingGroupBySite = (siteId, options = {}) => {
    return useQuery(["rangeSettingGroup", siteId], async () => await axiosClient.get<SieveGroup>(`/api/rangeSetting/group/site/${siteId}`).then((response) => response?.data), {
        staleTime: Infinity,
        enabled: !!siteId,
        ...options,
    });
};

export const useGetSieveGroups = (options = {}) => {
    return useQuery(["sieveGroups"], async () => await axiosClient.get<RangeSettingGroup[]>(`/api/rangeSetting/group`).then((response) => response?.data ?? []), { staleTime: Infinity, ...options });
};

export const useAddSieveGroup = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (sieveGroup: RangeSettingGroup) => {
            return axiosClient.post(`/api/rangeSetting/group`, {
                id: sieveGroup?.id,
                siteIds: sieveGroup.siteRangeSettingGroups.map((group) => group.siteId),
                rangeSettingIds: sieveGroup.rangeSettingGroupRangeSettings.map((group) => group.rangeSettingId),
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sieveGroups"]);
            },
        }
    );
};

export const useDeleteSieveGroupSiteMapping = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (sieveGroupId) => {
            return axiosClient.delete(`/api/rangeSetting/group/${sieveGroupId}`);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sieveGroups"]);
            },
        }
    );
};

export const useAddOrUpdateRangeSetting = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (rangeSetting: RangeSetting) =>
            axiosClient.put(`/api/rangeSetting/${rangeSetting.id}`, {
                id: rangeSetting?.id ?? 0,
                name: rangeSetting.name,
                isStartInclusive: rangeSetting.isStartInclusive.toString() === "true",
                startSieve: rangeSetting.startSieve,
                endSieve: rangeSetting.endSieve,
                displayOrder: Number(rangeSetting.displayOrder),
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["rangeSettings"]);
            },
        }
    );
};

export const useGetPlantDevicesByPlantId = (plantId: number) => {
    return useQuery(["plantDevices", plantId], async () => await axiosClient.get(`/api/plantDevice/${plantId}`).then((response) => response?.data ?? []), { enabled: !!plantId, staleTime: Infinity });
};

export const useGetSampleOverviewByPlantId = (plantId: number, startDate: any, endDate: any) => {
    return useQuery(["overview", plantId], async () => await axiosClient.get<LocationOverview[]>(`/api/Overview/${plantId}/${startDate}/${endDate}`).then((response) => response?.data ?? []), {
        enabled: false,
    });
};

export const useGetSamplesByFilter = (sampleFilters: SampleFilters) => {
    return useQuery(
        ["filteredSamples", sampleFilters],
        () =>
            axiosClient
                .get<SamplesByFilterResponse>("/api/Sample/filter", {
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => response?.data),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Samples fetch error: ${error?.message ?? "An error occurred"}`);
            },
        }
    );
};

export const useGetSieveSummations = (sampleFilters: SampleFilters) => {
    return useQuery(
        ["sieveSummations", sampleFilters],
        () =>
            axiosClient
                .get<SampleSummation[]>("/api/Sample/sieveSummations", {
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => response?.data),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Summations fetch error: ${error?.message ?? "An error occurred"}`);
            },
        }
    );
};

export const useGetRangeSummations = (sampleFilters: SampleFilters) => {
    return useQuery(
        ["rangeSummations", sampleFilters],
        () =>
            axiosClient
                .get<RangeSummation[]>("/api/Sample/rangeSummations", {
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => response?.data),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Summations fetch error: ${error?.message ?? "An error occurred"}`);
            },
        }
    );
};

export const useGetCharacteristicSummations = (sampleFilters: SampleFilters) => {
    return useQuery(
        ["characteristicSummations", sampleFilters],
        () =>
            axiosClient
                .get<CharacteristicSummation[]>("/api/Sample/characteristicSummations", {
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => response?.data),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Summations fetch error: ${error?.message ?? "An error occurred"}`);
            },
        }
    );
};

export const useGetRepeatabilityCountByUser = (userId: string) => {
    return useQuery(["repeatabilityCountByUser", userId], () => axiosClient.get<number>(`/api/Sample/repeatabilityCount/${userId}`, {}).then((response) => response?.data), {
        enabled: !!userId,
        staleTime: Infinity,
        onError: (error: Error) => {
            toast.error(error?.message ?? "An error occurred");
        },
    });
};

export const useGetSamplesExport = () => {
    return useMutation((sampleIds: number[]) => axiosClient.post<File>(`/api/Sample/export`, sampleIds).then((response) => response?.data), {
        onError: (error: Error) => {
            toast.error(error?.message ?? "An error occurred");
        },
    });
};

export const useGetSamplesExportByFilters = () => {
    return useMutation(
        (sampleFilters: SampleFilters) =>
            axiosClient
                .get<File>(`/api/Sample/export/filter`, {
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => response?.data),
        {
            onError: (error: Error) => {
                toast.error(error?.message ?? "An error occurred");
            },
        }
    );
};

export const useVoidSampleMutation = (sampleFilters) => {
    const queryClient = useQueryClient();

    return useMutation((sampleId: number) => axiosClient.patch(`/api/Sample/void/${sampleId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries(["filteredSamples", sampleFilters]);
        },
    });
};

export const useGetLegacySamplesByFilter = (sampleFilters: SampleFilters) => {
    return useQuery(
        ["legacySamples", sampleFilters],
        () =>
            axiosClient
                .get("/api/legacySample", {
                    headers: { "Content-Type": "application/json" },
                    params: sampleFilters,
                    paramsSerializer: { indexes: true },
                })
                .then((response) => {
                    const csvData = new Blob([response?.data], { type: "text/csv" });
                    const downloadLink = document.createElement("a");
                    const url = window.URL.createObjectURL(csvData);

                    downloadLink.href = url;
                    downloadLink.download = `SamplesExport_${new Date().toISOString()}.csv`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    return response?.data ?? [];
                }),
        {
            enabled: false,
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Samples fetch error: ${error?.message ?? "An error occurred"}`);
            },
        }
    );
};
