import dayjs from "dayjs";

export const useRelativeTime = () => {
    const today = dayjs(new Date().toISOString()).endOf("day").toDate();
    const yesterday = dayjs(today).subtract(1, "day").startOf("day").toDate();
    const lastWeek = dayjs(today).subtract(1, "week").startOf("day").toDate();
    const lastYear = dayjs(today).subtract(1, "year").startOf("day").toDate();
    const twoYearsAgo = dayjs(today).subtract(2, "year").startOf("day").toDate();

    return { today, yesterday, lastWeek, lastYear, twoYearsAgo };
};
